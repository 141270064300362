import React, { useState } from "react";
import styled from "styled-components";
import logo from "../assets/gflogo.png";
import { Link, useHistory } from "react-router-dom";
import { Menu, Dropdown, Button } from "antd";
import "../styles/Header.css";
import flag from "../assets/USA-Flag.jpg";
import { FaBars } from "react-icons/fa";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import { Drawer } from "@mui/material";
import { simplifyId } from "../helper/simplifyId";
const { SubMenu } = Menu;
const rootSubmenuKeys = ["sub1", "sub2", "sub4"];

const Header = (props) => {
  const history = useHistory();
  const [toggle, setToggle] = useState(false);
  const [openKeys, setOpenKeys] = React.useState(["sub1"]);

  const onOpenChange = (keys) => {
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
    if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };
  const menu0 = (
    <Menu>
      <Menu.Item>
        <Link
          to="/shop-by-vehicle"
          onClick={() => props.handleClickIndex(0)}
          style={{ color: props.clickedIndex === 0 ? "#0082f3" : null }}
        >
          By Vehicle
        </Link>
      </Menu.Item>
      <Menu.Item>
        <Link
          to="/measure-filter"
          onClick={() => props.handleClickIndex(1)}
          style={{ color: props.clickedIndex === 1 ? "#0082f3" : null }}
        >
          How To Measure A Filter
        </Link>
      </Menu.Item>
      <Menu.Item>
        <Link
          to="/cleaner-care"
          onClick={() => props.handleClickIndex(2)}
          style={{ color: props.clickedIndex === 2 ? "#0082f3" : null }}
        >
          Cleaner Kits
        </Link>
      </Menu.Item>
      <Menu.Item>
        <Link
          to="/harley-filter"
          onClick={() => props.handleClickIndex(11)}
          style={{ color: props.clickedIndex === 11 ? "#0082f3" : null }}
        >
          Harley Filters
        </Link>
      </Menu.Item>
      <Menu.Item>
        <Link
          to="/universal-cylinder"
          onClick={() => props.handleClickIndex(4)}
          style={{ color: props.clickedIndex === 4 ? "#0082f3" : null }}
        >
          Universal Cone/Cylinder
        </Link>
      </Menu.Item>
      <Menu.Item>
        <Link
          to="/universal-round"
          onClick={() => props.handleClickIndex(5)}
          style={{ color: props.clickedIndex === 5 ? "#0082f3" : null }}
        >
          Universal Round
        </Link>
      </Menu.Item>
      <Menu.Item>
        <Link
          to="/air-cleaner"
          onClick={() => props.handleClickIndex(6)}
          style={{ color: props.clickedIndex === 6 ? "#0082f3" : null }}
        >
          Air Cleaner Assemblies
        </Link>
      </Menu.Item>
      <Menu.Item>
        <Link
          to="/cross-reference-brand"
          onClick={() => props.handleClickIndex(12)}
          style={{ color: props.clickedIndex === 12 ? "#0082f3" : null }}
        >
          Cross Reference Brand
        </Link>
      </Menu.Item>
      <Menu.Item>
        <Link
          to="/cross-reference-filters"
          onClick={() => props.handleClickIndex(13)}
          style={{ color: props.clickedIndex === 13 ? "#0082f3" : null }}
        >
          Cross Reference Filters
        </Link>
      </Menu.Item>
      <Menu.Item>
        <a href="/documents/Porsche_Flyer.pdf">Porsche Product Flyer</a>
      </Menu.Item>
      <Menu.Item>
        <a href="/documents/BB.pdf">Bargain Basement</a>
      </Menu.Item>
    </Menu>
  );
  const menu1 = (
    <Menu>
      <Menu.Item>
        <Link
          to="/about-us"
          onClick={() => props.handleClickIndex(7)}
          style={{ color: props.clickedIndex === 7 ? "#0082f3" : null }}
        >
          About Us
        </Link>
      </Menu.Item>
      <Menu.Item>
        <Link
          to="/our-guarantee"
          onClick={() => props.handleClickIndex(8)}
          style={{ color: props.clickedIndex === 8 ? "#0082f3" : null }}
        >
          Our Guarantee
        </Link>
      </Menu.Item>
      <Menu.Item>
        <Link
          to="/why-go-green"
          onClick={() => props.handleClickIndex(9)}
          style={{ color: props.clickedIndex === 9 ? "#0082f3" : null }}
        >
          How We Outperform
        </Link>
      </Menu.Item>
      <Menu.Item>
        <Link
          to="/private-label"
          onClick={() => props.handleClickIndex(10)}
          style={{ color: props.clickedIndex === 10 ? "#0082f3" : null }}
        >
          Private Label
        </Link>
      </Menu.Item>
      <Menu.Item>
        <Link to="/">Become An Affiliate</Link>
      </Menu.Item>
      <Menu.Item>
        <Link to="/">Become A Dealer</Link>
      </Menu.Item>
    </Menu>
  );
  const menu2 = (
    <Menu style={{ background: "#ddd", position: "static", borderRadius: 5 }}>
      {props.models?.map((model,key) => (
        <Menu.Item className="vehicle-dropdown-menu-item" key={key}>
          <Link onClick={(e)=>{scroll(e,simplifyId(model.slug))}}>{model?.title}</Link>
        </Menu.Item>
      ))}
    </Menu>
  );

  const handleToggle = (value) => {
    const elem = document.getElementById("top-navigation");
    if (value) {
      elem.style.top = "57px";
      // elem.style.height = "max-content";
    }
    if (!value) {
      elem.style.top = "-655px";
      // elem.style.height = "0px";
    }
    setToggle(!toggle);
  };

  const scroll = (e,id) => {

    e.preventDefault();
    id = simplifyId(id);

    const section = document.querySelector( `#${id}` );
    section?.scrollIntoView( { behavior: 'smooth', block: 'start' } );
  };
  
  return (
    <>
      <HeaderWrapper>
        <div className="w-nav-overlay" id="top-navigation">
          <nav role="navigation" className="nav-block">
            <Menu mode="inline" openKeys={openKeys} onOpenChange={onOpenChange}>
              <SubMenu key="sub1" title="Shop" icon={null}>
                <Menu.Item key="1">
                  <Link
                    to="/shop-by-vehicle"
                    onClick={() => props.handleClickIndex(0)}
                    style={{
                      color: props.clickedIndex === 0 ? "#0082f3" : null,
                    }}
                  >
                    By Vehicle
                  </Link>
                </Menu.Item>
                <Menu.Item key="2">
                  <Link
                    to="/measure-filter"
                    onClick={() => props.handleClickIndex(1)}
                    style={{
                      color: props.clickedIndex === 1 ? "#0082f3" : null,
                    }}
                  >
                    How to Measure A Filter
                  </Link>
                </Menu.Item>
                <Menu.Item key="3">
                  <Link
                    to="/cleaner-care"
                    onClick={() => props.handleClickIndex(2)}
                    style={{
                      color: props.clickedIndex === 2 ? "#0082f3" : null,
                    }}
                  >
                    Cleaner Kits
                  </Link>
                </Menu.Item>
                <Menu.Item key="4">
                  <Link
                    to="/harley-filter"
                    onClick={() => props.handleClickIndex(11)}
                    style={{
                      color: props.clickedIndex === 11 ? "#0082f3" : null,
                    }}
                  >
                    Harley Filters
                  </Link>
                </Menu.Item>
                <Menu.Item key="5">
                  <Link
                    to="/universal-cylinder"
                    onClick={() => props.handleClickIndex(4)}
                    style={{
                      color: props.clickedIndex === 4 ? "#0082f3" : null,
                    }}
                  >
                    Universal Cone/Cylinder
                  </Link>
                </Menu.Item>
                <Menu.Item key="6">
                  <Link
                    to="/universal-round"
                    onClick={() => props.handleClickIndex(5)}
                    style={{
                      color: props.clickedIndex === 5 ? "#0082f3" : null,
                    }}
                  >
                    Unviersal Round
                  </Link>
                </Menu.Item>
                <Menu.Item key="7">
                  <Link
                    to="/air-cleaner"
                    onClick={() => props.handleClickIndex(6)}
                    style={{
                      color: props.clickedIndex === 6 ? "#0082f3" : null,
                    }}
                  >
                    Air Cleaner Assemblies
                  </Link>
                </Menu.Item>
                <Menu.Item key="8">
                  <Link
                    to="/cross-reference-brand"
                    onClick={() => props.handleClickIndex(12)}
                    style={{
                      color: props.clickedIndex === 12 ? "#0082f3" : null,
                    }}
                  >
                    Cross Reference Brand
                  </Link>
                </Menu.Item>
                <Menu.Item key="9">
                  <Link
                    to="/cross-reference-filters"
                    onClick={() => props.handleClickIndex(13)}
                    style={{
                      color: props.clickedIndex === 13 ? "#0082f3" : null,
                    }}
                  >
                    Cross Reference Filters
                  </Link>
                </Menu.Item>
                <Menu.Item key="10">
                  <a href="../assets/documents/BB.pdf">Bargain Basement</a>
                </Menu.Item>
              </SubMenu>
              <SubMenu key="sub2" title="Why green filter?" icon={null}>
                <Menu.Item key="1">
                  <Link
                    to="/about-us"
                    onClick={() => props.handleClickIndex(7)}
                    style={{
                      color: props.clickedIndex === 7 ? "#0082f3" : null,
                    }}
                  >
                    About Us
                  </Link>
                </Menu.Item>
                <Menu.Item key="2">
                  <Link
                    to="/our-guarantee"
                    onClick={() => props.handleClickIndex(8)}
                    style={{
                      color: props.clickedIndex === 8 ? "#0082f3" : null,
                    }}
                  >
                    Our Guarantee
                  </Link>
                </Menu.Item>
                <Menu.Item key="3">
                  <Link
                    to="/why-go-green"
                    onClick={() => props.handleClickIndex(9)}
                    style={{
                      color: props.clickedIndex === 9 ? "#0082f3" : null,
                    }}
                  >
                    How We Outperform
                  </Link>
                </Menu.Item>
                <Menu.Item key="4">
                  <Link
                    to="/private-label"
                    onClick={() => props.handleClickIndex(10)}
                    style={{
                      color: props.clickedIndex === 10 ? "#0082f3" : null,
                    }}
                  >
                    Private Label
                  </Link>
                </Menu.Item>
                <Menu.Item key="5">
                  <Link to="/">Become An Affiliate</Link>
                </Menu.Item>
                <Menu.Item key="6">
                  <Link to="/">Become A Dealer</Link>
                </Menu.Item>
              </SubMenu>
              <Menu.Item key="sub3">
                <Link
                  onClick={() => {
                    props.handleClickIndex(2);
                  }}
                  to="/cleaner-care"
                >
                  Cleaner & Care
                </Link>
              </Menu.Item>
              <Menu.Item key="sub4">
                <Link
                  onClick={() => {
                    props.handleClickIndex(10);
                  }}
                  to="/contact-us"
                >
                  Contact Us
                </Link>
              </Menu.Item>
            </Menu>
          </nav>
        </div>
        <div className="r-navbar w-nav">
          <div className="container w-clearfix">
            <div
              className="menu-button"
              onClick={() =>
                toggle ? handleToggle(false) : handleToggle(true)
              }
            >
              <FaBars />
            </div>

            <Link to="/" className="brand w-nav-brand w--current">
              <img src={logo} alt="logo" width="150" />
            </Link>
            <div className="nav-links">
              <Dropdown
                overlay={menu0}
                placement="bottomCenter"
                overlayStyle={{ zIndex: 100000 }}
                // trigger={["click"]}
              >
                <Button>Shop</Button>
              </Dropdown>
              <Dropdown
                overlay={menu1}
                placement="bottomCenter"
                overlayStyle={{ zIndex: 100000 }}
                // trigger={["click"]}
              >
                <Button>Why Green Filter?</Button>
              </Dropdown>

              <Button
                onClick={() => {
                  props.handleClickIndex(2);
                  history.push("/cleaner-care");
                }}
              >
                {" "}
                Cleaner & Care
              </Button>

              <Button
                onClick={() => {
                  props.handleClickIndex(10);
                  history.push("/contact-us");
                }}
              >
                Contact Us
              </Button>
            </div>
            <div className="right-nav-div">
              <img className="icon flag" src={flag} alt="flag" />
            </div>
          </div>


          {props.isVehicle && (
            <div className="hero-header">
              <div className="row-3 w-row">
                <div className="column-3 _0 w-col w-col-9">
                  <h1 className="hero-heading-1 left smaller">
                    {props.make.title} Performance Air Filters
                  </h1>
                </div>
                <div className="column-2 w-col w-col-3">
                  <div className="w-form" id="menu2-dropdown-container">
                    <Dropdown
                      overlay={menu2}
                      trigger={["click"]}
                      overlayStyle={{ zIndex: 100000 }}
                      getPopupContainer={() => document.getElementById('menu2-dropdown-container') || document.body}
                    >
                      <div className="vehicle-dropdown-btn">
                        <span style={{ flexGrow: 1, textAlign: "left" }}>
                          Select a {props.make.title} Model
                        </span>
                        <MdOutlineKeyboardArrowDown />
                      </div>
                    </Dropdown>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </HeaderWrapper>
    </>
  );
};

export default Header;

const HeaderWrapper = styled.div`
  position: fixed;
  left: 0px;
  top: 0px;
  right: 0px;
  z-index: 99996;
  padding: 0 10%;

  #menu2-dropdown-container .ant-dropdown-placement-topCenter {
    position: fixed;
    }

  .r-navbar {
    display: block;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    background-color: #000;
    box-shadow: 1px 1px 6px 0 rgb(0 0 0 / 7%);
  }

  .w-nav {
    position: relative;
    z-index: 9999;
  }

  .w-nav:before,
  .w-nav:after {
    content: " ";
    display: table;
  }
  .container {
    display: block;
    margin-right: 3%;
    margin-left: 3%;
    padding: 10px 0;
    z-index: 1000;
  }
  .brand {
    margin-top: 8px;
    margin-right: 0px;
  }
  .w-nav-brand {
    position: relative;
    text-decoration: none;
    color: #333333;
  }
  img {
    max-width: 100%;
    vertical-align: middle;
    display: inline-block;
    border: 0;
  }
  .nav-links {
    display: inline-block;
    margin-left: 5px;
  }
  .ant-btn {
    background: transparent;
    color: #fff;
    font-size: 15px;
    font-family: Lato, sans-serif;
    border: none;
    padding: 15px 20px;
  }
  .ant-btn:hover,
  .ant-btn:focus {
    color: rgba(256, 256, 256, 0.5);
  }
  .right-nav-div {
    position: absolute;
    top: 0;
    right: 0;
    display: block;
    float: right;
  }
  .icon.flag {
    width: 50px;
    margin-right: 7px;
    margin-left: 0px;
    margin-top: 10px;
  }
  .icon {
    position: relative;
    top: 12px;
    width: 25px;
  }
  .menu-button {
    display: none;
    position: absolute;
    left: 0;
    color: #fff;
    padding: 18px;
  }

  .hero-header {
    position: relative;
    z-index: 800;
    height: 100px;
    padding-top: 0px;
    background-color: black;
  }

  .row-3 {
    position: static;
    width: 100%;
    padding-right: 5%;
    padding-left: 5%;
    background-color: transparent;
  }

  .w-row:before,
  .w-row:after {
    content: " ";
    display: table;
  }
  .column-3._0 {
    margin-top: 0px;
    padding-left: 0px;
  }
  .w-col-9 {
    width: 75%;
  }
  .w-col-3 {
    width: 25%;
  }
  .w-col {
    position: relative;
    float: left;
    min-height: 1px;
    padding-left: 10px;
    padding-right: 10px;
  }
  .hero-heading-1.left.smaller {
    margin-top: 5px;
    font-size: 24px;
    text-transform: capitalize;
  }
  .hero-heading-1.left {
    margin-bottom: 15px;
    text-align: left;
    text-shadow: 1px 1px 6px rgb(0 0 0 / 24%);
  }
  .hero-heading-1 {
    font-family: Lato, sans-serif;
    color: #fff;
    font-weight: 400;
    letter-spacing: 0.75px;
  }
  .column-2 {
    height: 100px;
    text-align: right;
  }
  .w-form {
    margin: 0 0 15px;
    position: absolute;
    right: 0;
    bottom: 15px;
    width: 296px;
  }
  .vehicle-dropdown-btn {
    position: relative;
    left: 0px;
    right: 0px;
    padding: 15px 40px;
    border-radius: 5px;
    background-color: #00ad23;
    font-family: Lato, sans-serif;
    color: #fff;
    user-select: none;
    display: inline-block;
    cursor: pointer;
    display: flex;
  }
  .vehicle-dropdown-btn svg {
    font-size: 22px;
    color: #fff;
    margin-left: 10px;
  }

  .w-nav-overlay {
    position: fixed;
    overflow: hidden;
    width: 100%;
    top: -655px;
    transition: top 0.4s, display 0.4s;
    display: none;
  }

  .nav-block {
    width: 100%;
    margin-left: 5px;
    float: left;
    justify-content: space-between;
    flex: 0 auto;
    background-color: #000;
  }

  .nav-block .ant-menu {
    background: #000 !important;
    color: #fff;
    font-size: 15px;
    font-family: "Lato", sans-serif;
  }

  .nav-block .ant-menu-submenu-title {
    padding-left: 15px !important;
  }

  .nav-block .ant-menu-submenu-arrow {
    display: none;
  }

  .nav-block .ant-menu-sub.ant-menu-inline > .ant-menu-item {
    padding-left: 25px !important;
  }
  .nav-block .ant-menu-submenu-title:hover,
  .nav-block .ant-menu-item:hover,
  .ant-menu-item-selected,
  .ant-menu-submenu-selected,
  .nav-block a:hover {
    color: #757575;
  }

  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected,
  .ant-menu-item:active,
  .ant-menu-submenu-title:active {
    background-color: #000;
  }

  .nav-block a {
    color: #fff;
    transition: color 0.3s ease-in-out !important;
  }

  .nav-block .ant-menu-item-only-child {
    padding-left: 15px !important;
  }
  @media screen and (max-width: 767px) {
    padding: 0;
  }
  @media screen and (max-width: 912px) {
    .nav-links {
      display: none !important;
    }
    .container {
      text-align: center;
      position: relative;
      margin-left: 3%;
    }
    .icon.flag {
      margin-top: 0;
    }
    .menu-button {
      display: inline-block;
    }
    .right-nav-div {
      right: 0 !important;
    }
    .w-nav-overlay {
      display: block;
    }
  }

  @media screen and (max-width: 479px) {
    .hero-heading-1.left.smaller {
      text-align: center;
    }
    .w-form {
      width: 100%;
    }
  }

  @media screen and (max-width: 1100px) {
    .w-col-9 {
      width: 100%;
    }
    .w-col-3 {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .w-form {
      position: static;
    }
  }
`;
